<template>
  <div id="oee" ref="appRef">
    <div class="bg">
      <div class="oee-body">
        <MyHeader :title="title"></MyHeader>

        <div class="body-box">
          <!-- 左侧数据 -->
          <div class="left-box">
            <div>
              <dv-border-box-12>
                <left :oee_rank="this.oee_rank" />
              </dv-border-box-12>
            </div>
          </div>

          <!-- 右侧数据 -->
          <div class="right-box">
            <div class="right-top-box">
              <!--              <div>-->
              <!--                <dv-border-box-12>-->
              <!--                  <rightTopLeft :daily_loss="this.daily_loss"/>-->
              <!--                </dv-border-box-12>-->
              <!--              </div>-->
              <div>
                <dv-border-box-12>
                  <rightTopRight :loss_reason="this.loss_reason" />
                </dv-border-box-12>
              </div>
            </div>
            <div class="right-bottom-box">
              <div>
                <dv-border-box-12>
                  <rightBottom :oee_trend="this.oee_trend" />
                </dv-border-box-12>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MyHeader from "../../components/header/myHeader.vue";
import drawMixin from "@/utils/drawMixin";
import centerRight2 from "@/views/centerRight2.vue";
import bottomRight from "@/views/bottomRight.vue";
import centerLeft1 from "@/views/centerLeft1.vue";
import centerLeft2 from "@/views/centerLeft2.vue";
import center from "@/views/center.vue";
import centerRight1 from "@/views/centerRight1.vue";
import bottomLeft from "@/views/bottomLeft.vue";
import left from "@/views/oee/left.vue";
import rightTopLeft from "@/views/oee/rightTopLeft.vue";
import rightTopRight from "@/views/oee/rightTopRight.vue";
import rightBottom from "@/views/oee/rightBottom.vue";
import oee from "@/api/oee";

export default defineComponent({
  name: "oee",
  mixins: [drawMixin],
  data() {
    return {
      title: "设备综合效率看板",
      oee_rank: [],
      loss_reason: [],
      oee_trend: [],
    };
  },
  components: {
    rightBottom,
    left,
    rightTopLeft,
    rightTopRight,
    bottomLeft,
    centerRight1,
    center,
    centerLeft2,
    centerLeft1,
    bottomRight,
    centerRight2,
    MyHeader /**/,
  },
  mounted() {
    // setInterval 轮询策略待实现
    // setInterval(() => {
    //   this.fetchData();
    // }, 3000);
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await oee
        .getOeeAnalysis()
        .then((response) => {
          console.log(response);
          let rank = response.data.data.OEERankPercent;
          rank.forEach((item) => {
            item.Run = (item.Run * 100).toFixed(2);
            item.Wating = (item.Wating * 100).toFixed(2);
            item.Closed = (item.Closed * 100).toFixed(2);
            item.Error = (
              100 -
              (parseFloat(item.Run) +
                parseFloat(item.Wating) +
                parseFloat(item.Closed))
            ).toFixed(2);
          });
          //!
          this.oee_rank = rank.slice(0,18);

          this.loss_reason = response.data.data.OEEStatusCount;

          this.oee_trend = response.data.data.OEEStatusDayCount;
          this.oee_trend = this.oee_trend.sort((a, b) => {
            // date str compare
            return new Date(a.date).getTime() - new Date(b.date).getTime();
          });
          console.log(this.oee_trend, "this.oee_trend");
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
  },
});
</script>

<style scoped lang="scss">
@import "../../assets/scss/oee.scss";
</style>
