<template>
  <div>
    <Chart :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      cdata: {
        category: [],
        data: [],
      }
    };
  },
  components: {
    Chart,
  },
  props: {
    oee_rank: {
      type: Array,
      required: true,
    },
  },
  mounted() {
  },
  methods: {
    getPrefix(val) {
      if (val < 10) {
        return 'M00' + val
      }
      return 'M0' + val
    },
  },
  watch: {
    oee_rank: {
      handler: function (newVal) {
        // console.log("-----------------newval")
        // console.log(newVal)
        this.cdata.category = []
        this.cdata.data = [[], [], [], []]
        let length = newVal.length
        newVal.forEach((item, index) => {
          this.cdata.category[index] = this.getPrefix(item.MachineID)
          /*To do*/
          this.cdata.data[0][length - index - 1] = item.Run
          this.cdata.data[3][length - index - 1] = item.Wating
          this.cdata.data[2][length - index - 1] = item.Closed
          this.cdata.data[1][length - index - 1] = item.Error
        })
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
